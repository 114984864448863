.p-20pt {
  padding: 20pt;
}

.mb-25pt {
  margin-bottom: 25pt;
}

.mb-40pt {
  margin-bottom: 40pt;
}

.ml-10pt {
  margin-left: 10pt;
}

.mr-5pt {
  margin-right: 5pt;
}

.well2 {
  border: 0px solid #c9c9c9;
  border-radius: 3px;
  background-color: #eef2f1;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.gap-15pt {
  gap: 15pt;
}

.text-xl {
  font-size: 20px;
  line-height: 28px;
}
